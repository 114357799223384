
import { defineComponent } from "vue";
import Thumb from "@/common/Thumb.vue";
import { GenericContent } from "@/services/paginator.service";
import Chips from "@/common/Chips.vue";

export default defineComponent({
  name: "ArticleTile",
  components: { Chips, Thumb },
  props: {
    item: Object as () => GenericContent,
  },
});
