import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5cb9f86c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "paginator-list" }
const _hoisted_2 = {
  key: 0,
  class: "container"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "show-more"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_article_tile = _resolveComponent("article-tile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.paginator)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flipList, (row, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "columns",
              key: index
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "column is-one-third",
                  key: item.id,
                  onClick: ($event: any) => (_ctx.goto(item))
                }, [
                  _createVNode(_component_article_tile, {
                    item: item,
                    "data-aos": "fade",
                    "data-aos-delay": 100 + index * 50
                  }, null, 8, ["item", "data-aos-delay"])
                ], 8, _hoisted_3))
              }), 128))
            ]))
          }), 128)),
          (!_ctx.paginator.isLast)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("button", {
                  class: "button is-primary",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMore()))
                }, " See more ")
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}