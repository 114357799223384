import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_article_details = _resolveComponent("article-details")!
  const _component_article_paginator = _resolveComponent("article-paginator")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.slug)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_article_details, { slug: _ctx.slug }, null, 8, ["slug"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_article_paginator)
        ]))
  ]))
}