
import { StaticContent } from "@/services/contents.service";
import { defineComponent } from "vue";
import { GenericContent, Paginator, paginatorService, } from "@/services/paginator.service";
import ArticleTile from "@/modules/articles/ArticleTile.vue";

export default defineComponent({
  name: "ArticlePaginator",
  components: {ArticleTile },
  props: {},
  data() {
    return {
      page: undefined as StaticContent | undefined,
      paginator: undefined as Paginator | undefined,
      flipList: [] as GenericContent[][],
      pageNo: 1,
      pageLimit: 9,
    };
  },
  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {
      paginatorService
        .paginator("articles", {
          page: this.pageNo,
          limit: this.pageLimit,
        })
        .then((paginator) => {
          this.paginator = paginator;
          this.flipList = this.flip(3, [...paginator.list]);
        });
    },
    showMore() {
      this.pageNo += 1;
      paginatorService
        .paginator("articles", {
          page: this.pageNo,
          limit: this.pageLimit,
        })
        .then((paginator) => {
          this.paginator = paginator;
          this.flipList.push(...this.flip(3, [...paginator.list]));
        });
    },
    flip(by = 3, list: GenericContent[]) {
      const fipped = [];
      const s = Math.ceil(list.length / by);
      for (let i = 0; i < s; i++) {
        fipped.push(list.splice(0, by));
      }
      return fipped;
    },
    goto(item: GenericContent) {
      this.$router.push("/articles/" + item.slug);
    },
  },
});
