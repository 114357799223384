
import { defineComponent } from "vue";
import { GenericContent, paginatorService } from "@/services/paginator.service";
import { setPageDescription, setPageTitle } from "@/common/meta.utils";
import PageHeader from "@/common/PageHeader.vue";

export default defineComponent({
  name: "ArticleDetails",
  components: { PageHeader },
  props: {
    slug: String as () => string,
  },
  data() {
    return {
      isItem: false,
      item: undefined as GenericContent | undefined,
    };
  },
  mounted() {
    if (this.slug) {
      this.loadContent(this.$route.params.slug as string);
    }
  },

  methods: {
    loadContent(slug: string) {
      paginatorService.findBySlug("articles", slug).then((item) => {
        if (!item) {
          this.$router.push("/articles/");
        } else {
          this.item = item;
          setPageTitle(item?.title);
          setPageDescription(item?.lead);
        }
      });
    },
  },
  watch: {
    slug(slug) {
      if (slug) {
        this.loadContent(slug);
      } else {
        this.item = undefined;
      }
    },
  },
});
